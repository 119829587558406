/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/bootstrap@3.4.1/dist/js/bootstrap.min.js
 * - /npm/bootstrap-datepicker@1.9.0/dist/js/bootstrap-datepicker.min.js
 * - /npm/bootstrap-datepicker@1.9.0/dist/locales/bootstrap-datepicker.es.min.js
 * - /npm/bootstrap-slider@9.2.0/dist/bootstrap-slider.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/magnific-popup@1.1.0/dist/jquery.magnific-popup.min.js
 * - /npm/devbridge-autocomplete@1.2.27/dist/jquery.autocomplete.min.js
 * - /npm/jquery-form@4.0.1/dist/jquery.form.min.js
 * - /npm/bootstrap-select@1.13.18/dist/js/bootstrap-select.min.js
 * - /npm/nprogress@0.2.0/nprogress.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/moment@2.29.1/moment.min.js
 * - /npm/toastr@2.1.4/build/toastr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
